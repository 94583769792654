import React from 'react'
import { images } from 'config/images'

export const WebAppDevelopmentProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Web application development services <br /> using{' '}
        <span className="heading-red">industry leading technologies</span>
      </>
    ),
    bannerContent: (
      <>
        Harness the power of modern web frameworks to build progressive web apps
        that scale
      </>
    ),
    BannerAlt: 'Web application development services',
    buttonName: 'Let’s get started!',
    buttonLink: '/contact/',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        <span className="highlight-head">Why Choose Simform</span> for Web
        Development?
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            We are a web development company that purely focuses on building
            fully functional, robust, and scalable web applications. We build
            custom web apps using the right technology stacks that align with
            your business objectives and help you achieve maximum growth. Our
            end goal is to build quality web applications for you that maximize
            resource utilization at a low cost.
            <br /> <br />
            Our web application development services include design and
            development, testing, and support and maintenance. Along with that,
            our experienced web app developers also help you in technology
            consultation, modernizing legacy architectures, and migrating to new
            web architecture.
          </>
        ),
      },
      {
        detailsList: [
          'Experts in migrating to microservices',
          'Innovative in modularizing existing architectures',
          'Experience in building Service-Oriented-Architectures (SOA)',
          'Consistent delivery by following CI/CD approaches',
          'Strict adherence to dedicated QA practices',
          'Support and maintenance round-the-clock',
          'Tech-savvy in integrating the cloud power and web solutions',
          'Guaranteed results in improving UI/UX interactivity',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  customDevProps: {
    serviceHeading: (
      <>
        <span className="highlight-head">Custom Web Application</span>{' '}
        Development Offerings
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: <>SaaS Apps Development Services</>,
        serviceParagraph: (
          <>
            Our SaaS consultants will help you build{' '}
            <strong>multi-tenant, secure, and robust</strong> SaaS solutions so
            that you can serve customers better.
          </>
        ),
        serviceIcon: images.ServiceEnterpriseAaplication,
        serviceAlt: 'Saas',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: <>Front end Development Services</>,
        serviceParagraph: (
          <>
            Build modern <strong>single page web applications</strong> using the
            reusable, dynamic, component driven architecture of React.js,
            Angular.js, Vue.js.{' '}
          </>
        ),
        serviceIcon: images.iconReactJs,
        serviceAlt: 'React',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: <>Progressive Web Apps Development Services</>,
        serviceParagraph: (
          <>
            Bring <strong>features and performance</strong> we expect from
            native apps to any browser and across platforms without any
            interruption or loss of speed.
          </>
        ),
        serviceIcon: images.iconProgressiveWeb,
        serviceAlt: 'Progressive',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: <>Backend & Node.js Development Services</>,
        serviceParagraph: (
          <>
            Our software architects <strong>design solid backend</strong> to
            accommodate scale, flexibility to integrate, advanced data
            structures, and cutting edge security.
          </>
        ),
        serviceIcon: images.iconBackendNode,
        serviceAlt: 'Backend & Node.js',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: <>E-commerce Web Apps Development Services</>,
        serviceParagraph: (
          <>
            Using Shopify, Magento, and custom frameworks, we help you{' '}
            <strong>build e-shops</strong> and B2B eCommerce portals that boost
            your growth.
          </>
        ),
        serviceIcon: images.iconEcommerceWeb,
        serviceAlt: 'E-commerce',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: <>Custom Web Apps Development for Enterprises</>,
        serviceParagraph: (
          <>
            <strong>Get a performance boost</strong> across the organization by
            building web-based apps like inventory-, transport-, learning, and
            process management systems.
          </>
        ),
        serviceIcon: images.sCustomwebAppEnterprises,
        serviceAlt: 'enterprises',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working with
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    processPara: `Building scalable web apps using modern frameworks, structured 
    architecture patterns, and user-centric UI/UX designs are the things that
    differentiate us from our competitors. In a nutshell, our custom web
    application development services are a promised commitment to
    quality and on-time delivery. `,
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Progressive web applications using modern frameworks',
        workingBenefitsContent: (
          <>
            We have over a decade of experience in building{' '}
            <strong>world-class B2B and B2C</strong> applications focusing on
            M&E, Healthcare, Fintech, Transportation, RTC, AdTech, e-Learning,
            and data analytics.
          </>
        ),
        benefitImgAlt: 'B2B and B2C apps',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsContent: (
          <>
            Our web app engineering team has delivered products to{' '}
            <strong>over 400 clients</strong>, including global brands such as
            Google, Pepsi, Hilton, Redbull, and Sony Music. Our technology stack
            includes the newest technologies such as React.js, Angular.js,
            Vue.js, PostgreSQL, Python, MongoDB, Node.js, etc.
          </>
        ),
        benefitImgAlt: 'Modern frameworks',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Structured architectural patterns for developing systems at scale',
        workingBenefitsContent: (
          <>
            We help build <strong>large scale web applications</strong> adhering
            to Google’s definition of “progressiveness”, making them fast even
            on 2G networks, offline-available and easily installable across
            devices.
          </>
        ),
        benefitImgAlt: 'Large scale web apps',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Great web apps start with great front-end',
        workingBenefitsContent: (
          <>
            Slow load times, poor performance, and dated UX design can impact
            your bottom line. Your business depends on providing{' '}
            <strong>differentiated and engaging</strong> experiences.
          </>
        ),
        benefitImgAlt: 'Engaging frontend',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Navigate web complexities with experienced consultants',
        workingBenefitsContent: (
          <>
            There are a lot of firms that can build web apps. But there are few
            who can develop <strong>world-class web apps</strong> that will
            drive results. Our team coaches you through every tech decision
            critical to you.
          </>
        ),
        benefitImgAlt: 'Web Complexities',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Portfolio of <span className="highlight-head">Web Applications</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">Customers Say </span> <br />
        About Our Services
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },

  developSoftAgileProps: {
    heading: (
      <>
        How We Develop
        <br /> Web Apps <span className="highlight-head">Using Agile</span>
      </>
    ),
    sectionPara: '',
    headingLeftImgProps: [
      {
        src: images.serviceUser2,
        srcSet: images.serviceUser22x,
      },
      {
        src: images.serviceUser3,
        srcSet: images.serviceUser32x,
      },
      {
        src: images.serviceUser1,
        srcSet: images.serviceUser12x,
      },
    ],
    headingRightImgProps: [
      {
        src: images.serviceUser4,
        srcSet: images.serviceUser42x,
      },
      {
        src: images.serviceUser5,
        srcSet: images.serviceUser52x,
      },
      {
        src: images.serviceUser6,
        srcSet: images.serviceUser62x,
      },
    ],
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.ServiceSprintPlanning,
        workFlowIconAlt: 'Team',
        workFlowTitle: 'Sprint planning',
        workFlowParagraph:
          'Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.ServiceTechArchitecture,
        workFlowIconAlt: 'Sprint',
        workFlowTitle: 'Tech architecture',
        workFlowParagraph:
          'We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.ServiceStandups,
        workFlowIconAlt: 'Tech',
        workFlowTitle: 'Stand-ups & weekly demos',
        workFlowParagraph:
          'Stand ups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '4',
        workFlowIcon: images.ServiceCodeReviews,
        workFlowIconAlt: 'StandUps',
        workFlowTitle: 'Code reviews',
        workFlowParagraph:
          'Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
    ],
  },
  industriesServeProps: {
    heading: (
      <>
        <span className="highlight-head">Industries</span> We Serve
      </>
    ),
    tabContents: [
      {
        tabName: 'Finance',
        contentHeading: <>Bank analytics web platform</>,
        contentPara:
          'For Wealth Managers to mine valuable customer data, take actions in real-time and redefine the customer relationships with Bank Analytics Web App',
        contentSingleProps: [
          {
            singleblkImg: images.bank1,
            singleblkImg2x: images.bank12x,
          },
          {
            singleblkImg: images.bank2,
            singleblkImg2x: images.bank22x,
          },
          {
            singleblkImg: images.bank3,
            singleblkImg2x: images.bank32x,
          },
        ],
      },
      {
        tabName: 'Software',
        contentHeading: <>Video interview platform</>,
        contentPara:
          'An AI-powered assessment & video platform that leverages video interview, online examinations, coding platform & VM simulations to screen and hire candidates.',
        contentSingleProps: [
          {
            singleblkImg: images.videointer1,
            singleblkImg2x: images.videointer12x,
          },
          {
            singleblkImg: images.videointer2,
            singleblkImg2x: images.videointer22x,
          },
          {
            singleblkImg: images.videointer3,
            singleblkImg2x: images.videointer32x,
          },
        ],
      },
      {
        tabName: 'Healthcare',
        contentPara:
          'Healthcare-centric PWAs and web portals that improve communication and collaboration to find, share information faster, and improve patient engagement.',
        contentSingleProps: [
          {
            singleblkImg: images.elearning1,
            singleblkImg2x: images.elearning12,
          },
          {
            singleblkImg: images.elearning2,
            singleblkImg2x: images.elearning22,
          },
          {
            singleblkImg: images.elearning3,
            singleblkImg2x: images.elearning32,
          },
        ],
      },
      {
        tabName: 'Consumer',
        contentHeading: <>Web-based communication platform</>,
        contentPara:
          'Scalable API-driven web-based instant messaging & video conference platform with real-time data communication built over serverless technology.',
        contentSingleProps: [
          {
            singleblkImg: images.webbased1,
            singleblkImg2x: images.webbased12x,
          },
          {
            singleblkImg: images.webbased2,
            singleblkImg2x: images.webbased22x,
          },
          {
            singleblkImg: images.webbased3,
            singleblkImg2x: images.webbased32x,
          },
        ],
      },
      {
        tabName: 'Education',
        contentHeading: <>Educational content management portal</>,
        contentPara:
          'Online Content Management Portal for the university to keep a shared repository of text-based learning materials, video content and exam coordination.',
        contentSingleProps: [
          {
            singleblkImg: images.educational1,
            singleblkImg2x: images.educational12x,
          },
          {
            singleblkImg: images.educational2,
            singleblkImg2x: images.educational22x,
          },
          {
            singleblkImg: images.educational3,
            singleblkImg2x: images.educational32x,
          },
        ],
      },
      {
        tabName: 'Retail',
        contentHeading: <>Web-based e-commerce store</>,
        contentPara:
          'A dedicated e-Commerce Web App with product catalogues, advanced search results, online payment and integrated loyalty programs to reach more customers. ',
        contentSingleProps: [
          {
            singleblkImg: images.retailweb1,
            singleblkImg2x: images.retailweb12x,
          },
          {
            singleblkImg: images.retailweb2,
            singleblkImg2x: images.retailweb22x,
          },
          {
            singleblkImg: images.retailweb3,
            singleblkImg2x: images.retailweb32x,
          },
        ],
      },
      {
        tabName: 'Manufacturing',
        contentHeading: <>Inventory management portal</>,
        contentPara:
          'Web portal for supply chain management for multiple locations, safety stock, re-order points, cycle counts, demand and distribution requirements planning.',
        contentSingleProps: [
          {
            singleblkImg: images.inventory1,
            singleblkImg2x: images.inventory12x,
          },
          {
            singleblkImg: images.inventory2,
            singleblkImg2x: images.inventory22x,
          },
          {
            singleblkImg: images.inventory3,
            singleblkImg2x: images.inventory32x,
          },
        ],
      },
      {
        tabName: 'Sports',
        contentHeading: <>Athlete training platform</>,
        contentPara:
          'Custom athlete training platform for sports practitioners with athlete data & performance management, motion tracking and athlete tracking service.',
        contentSingleProps: [
          {
            singleblkImg: images.athlete1,
            singleblkImg2x: images.athlete12x,
          },
          {
            singleblkImg: images.athlete2,
            singleblkImg2x: images.athlete22x,
          },
          {
            singleblkImg: images.athlete3,
            singleblkImg2x: images.athlete32x,
          },
        ],
      },
      {
        tabName: 'Social Media',
        contentHeading: <>Social media platform for pets</>,
        contentPara:
          'A networking platform that leverages personal relationships and social circles to connect pet owners with pet sitters, boarding schools & veterinarians.',
        contentSingleProps: [
          {
            singleblkImg: images.socialmedia1,
            singleblkImg2x: images.socialmedia12x,
          },
          {
            singleblkImg: images.socialmedia2,
            singleblkImg2x: images.socialmedia22x,
          },
          {
            singleblkImg: images.socialmedia3,
            singleblkImg2x: images.socialmedia32x,
          },
        ],
      },
    ],
  },
  awardsRecognitionProps: {
    heading: 'Awards & Recognition',
    awardBlockProps: [
      {
        awardBlockImg: images.mspDataAiAzure2x,
      },
      {
        awardBlockImg: images.mspDigitalAppInnovationAzure,
      },
      {
        awardBlockImg: images.mspInfrastructureAzure,
      },
      {
        awardBlockImg: images.databricksLogo,
      },
      {
        awardBlockImg: images.cmmiLevel3,
      },
      {
        awardBlockImg: images.theAmericanBusinessAwards,
      },
      {
        awardBlockImg: images.globalOutsourcing100,
      },
      {
        awardBlockImg: images.greatPlaceToWork,
      },
      {
        awardBlockImg: images.glassdoorLogo2x,
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle: 'What types of web apps can you develop?',
        faqPara: (
          <>
            Web applications are specific software designed in a way that it
            runs on a web browser with web specific resources. So far we’ve
            developed different types of web apps which vary from a small static
            web app to an ERP solution.
            <br /> <br />
            1. Static Web Apps
            <br />
            2. Dynamic Web App
            <br />
            3. Online Store
            <br />
            4. Portal Web Apps
            <br />
            5. Animated Web App
          </>
        ),
      },

      {
        faqTitle: 'How much does it cost to develop a web app?',
        faqPara: (
          <>
            The cost of Web App development depends on many factors such as :
            <br /> <br />
            Development platform - PWA, SaaS
            <br /> <br />
            App categories - e-Commerce, Gaming, Entertainment, On-Demand
            Delivery
            <br /> <br />
            App Complexities - number of screens, limited data, need for API
            connection, admin console, user profiles
            <br /> <br />
            Resource location - Orlando or other cities in the USA. <br />{' '}
            <br />
            After having established these factors, we create a detailed
            description that includes user journeys, tech stack, project
            investment, and timeline. Contact us with your requirement to get
            the App development cost.
          </>
        ),
      },

      {
        faqTitle: 'What is the difference between Web App and SaaS?',
        faqPara: (
          <>
            Web apps are exclusively designed to be used from a web browser.
            These are combinations of server-side script (ASP .NET, PHP, etc.)
            and client-side script (HTML, JavaScript, etc.). The web browser
            (thin client) fetches the web server components installed at the
            backend infrastructure of the system for the heavy lifting in
            providing its core functional web services. Some common examples of
            web applications are Facebook, Medium, Twitter, Smashing Magazine,
            Flipkart, etc.
            <br /> <br />
            On the other hand, SaaS is an evolved web app. It’s equally used to
            access services over the internet like web apps but not always
            exclusively dependent on the web browser to work. SaaS products are
            built to give richer user customization experience and seamless
            integration with resilient and seamless back-end infrastructure.
            Some common examples are Evernote, Salesforce, Hubspot, Zoho,
            Dropbox. Etc.
          </>
        ),
      },

      {
        faqTitle: 'How does Progressive Web App work?',
        faqPara: (
          <>
            Progressive web app is a new software development methodology which
            is used to develop a hybrid of regular web pages and a mobile app.
            This new custom web application model strives to combine features
            offered by the web browsers with the benefits of native-like mobile
            experience. PWAs can be located on the search engines and can be
            accessed promptly using a browser itself.
            <br /> <br />
            The central goal of PWAs is to bridge the gap between native apps
            and web apps by translocating the benefits of the native apps to the
            mobile browser.
            <br />
            <br />
            PWA are:
            <br />
            <br />
            <ul>
              <li>
                reliable due to faster loading time and offline availability.
              </li>
              <li>
                faster operation with smooth animations and seamless navigation.
              </li>
              <li>
                engaging since it can be launched from the home screen and
                receive push notifications.
              </li>
            </ul>
          </>
        ),
      },
      {
        faqTitle: 'What are the business benefits of Progressive Web Apps?',
        faqPara: (
          <>
            The implementation of PWA will be beneficial to any business in the
            following way:
            <br />
            <br />
            <ul>
              <li>
                Cost cutting (one team for one technology instead of different
                units for browsers and individual native mobile apps.
              </li>
              <li>Improved page performance.</li>
              <li>
                Better visibility over the internet, mainly search engines.
              </li>
              <li>
                Easier and cost-friendly user acquisition of new users compared
                to the native mobile application.
              </li>
              <li>
                No problem related to mobile applications (long deployment time,
                Apple/Google verification, etc.)
              </li>
              <li>
                Increased user engagement (due to low page loading time, an
                increase in the number of new users in all browsers by 104%).
              </li>
              <li>
                Integration with any existing system which is operating as a
                data source back-end.
              </li>
            </ul>
          </>
        ),
      },
      {
        faqTitle: 'What is the difference between web API and web service',
        faqPara: (
          <>
            The general difference between the both is that all web services are
            APIs, but not all APIs are necessarily web services. Both are used
            to perform web communications where web services employ only three
            styles: SOAP, REST, and XML-PRC. Whereas web APIs may use different
            styles for communication between services based on the requirement.
          </>
        ),
      },
      {
        faqTitle: 'How long does it take to build a web application?',
        faqPara: (
          <>
            Time duration to build web applications depends on various factors
            such as complexity, modularity, a feature required, etc. Typically,
            web app developers take 2 to 3 weeks to build a small app, 3 to 6
            weeks for a medium complex app, and 2 to 3 months or more for a
            highly advanced app. If you want an accurate estimate and free
            consultation based on your requirements,
            <a href="/contact">
              {' '}
              feel free to reach out to our expert developers.
            </a>
          </>
        ),
      },
      {
        faqTitle:
          'Do you provide maintenance and support after developing a web app?',
        faqPara: (
          <>
            We offer after-sales customer support, and our web application
            developers are experts in identifying bottlenecks to ensure maximum
            performance, UX, and reduced downtime. Our development team is
            highly quality-centric, and we focus on ensuring your app adheres to
            OWASP TOP 10 to avoid security vulnerabilities. Our maintenance
            service includes upgrades, updates of existing features or adding
            new ones, and conducting periodical maintenance for web
            improvements.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },

  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/blog/web-application-architecture/',
        featurecardTitle: 'An Ultimate Guide of Web Application Architecture',
      },
      {
        blogLink: 'https://www.simform.com/blog/angular-vs-react/',
        featurecardTitle:
          'Angular vs React 2021 : Which JS Framework your Project Requires?',
      },
      {
        blogLink: 'https://www.simform.com/blog/frontend-technical-debt/',
        featurecardTitle:
          'Technical Debt At Frontend: How to Identify and Mitigate it',
      },
    ],
  },
}
